import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "registration-form__container" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "registration-form__date-container" }
const _hoisted_5 = { class: "registration-form__date-container" }
const _hoisted_6 = {
  key: 0,
  class: "registration-form__checkbox-container"
}
const _hoisted_7 = {
  for: "verify",
  class: "registration-form__checkbox-label"
}
const _hoisted_8 = {
  key: 1,
  class: "registration-form__checkbox-container"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "registration-form__button-inner-container" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "spinner"
}

import { ref, watch, onMounted } from "vue";
import { formFieldEnum } from "@/types/formFieldEnum";
import { stringToDate, tzAgnosticDateUTC } from "@/helpers/dateHelpers";
import {
  validateFilled,
  validateAgainstRegex,
} from "@/helpers/validationHelpers";
import { AllUnits } from "@/constants/allUnits";
import { toast, type ToastOptions } from "vue3-toastify";

/*Testing vue datepicker as solution to Safari issue*/
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";


export default /*@__PURE__*/_defineComponent({
  __name: 'registrationForm',
  props: {
  acknowledgeStatement: {
    type: String,
    required: true,
  },
  documentType: {
    type: String,
    required: true,
  },
  document: {
    type: Object as any,
  },
},
  emits: ["updateDocument"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const arrivalDateRef = ref();
const departureDateRef = ref();

const formInputModel = ref(["", "", "", "", "", "", "", "", "", false]);
const isBypassed = ref(false);

onMounted(() => {
  if (props.document) {
    formInputModel.value[formFieldEnum.NAME] = props.document.Name;
    formInputModel.value[formFieldEnum.EMAIL] = props.document.Email;
    formInputModel.value[formFieldEnum.PHONE] = props.document.GuestPhone;
    formInputModel.value[formFieldEnum.ALT_PHONE] = props.document.AltPhone;
    formInputModel.value[formFieldEnum.UNIT_BOOKED] = props.document.Unit;
    arrivalDateRef.value = props.document.ArrivalDate;
    formInputModel.value[formFieldEnum.NUM_ADULTS] = props.document.TotalAdults;
    formInputModel.value[formFieldEnum.NUM_CHILDREN] =
      props.document.TotalChildren;
    setArrivalDateRef();

    setTimeout(() => {
      departureDateRef.value = props.document.DepartureDate;
      setDepartureDateRef();
    }, 0);
  }
});
// clears arrival and departure if arrival date is cleared
watch(formInputModel.value, () => {
  if (formInputModel.value[5] === "") formInputModel.value[6] = "";
});

watch(
  () => formInputModel.value[5],
  () => {
    formInputModel.value[6] = "";
  }
);

const formValidationRefs = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);

const isPending = ref(false);

const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);
const PHONE_REGEX = new RegExp("^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$");

async function handleSubmit(): Promise<void> {
  const submittedDocument = {
    Name: formInputModel.value[formFieldEnum.NAME],
    Email: formInputModel.value[formFieldEnum.EMAIL],
    GuestPhone: formInputModel.value[formFieldEnum.PHONE],
    AltPhone: formInputModel.value[formFieldEnum.ALT_PHONE],
    Unit: formInputModel.value[formFieldEnum.UNIT_BOOKED].toString(),
    ArrivalDate: formInputModel.value[formFieldEnum.ARRIVAL_DATE],
    DepartureDate: formInputModel.value[formFieldEnum.DEPARTURE_DATE],
    TotalAdults: formInputModel.value[formFieldEnum.NUM_ADULTS],
    TotalChildren: formInputModel.value[formFieldEnum.NUM_CHILDREN],
    Acknowledgement: formInputModel.value[formFieldEnum.VERIFY],
    RegistrationDate: new Date(),
    DurationOfStay:
      getDayDiff({
        ArrivalDate: arrivalDateRef.value,
        DepartureDate: departureDateRef.value,
      }) + 1,
  };

  formInputModel.value.forEach((field, index) => {
    applyValidationStyle(index);
  });

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    if (isBypassed.value) {
      try {
        isPending.value = true;

        await fetch("https://app-y4uhxvobbq-uc.a.run.app/bypass-checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            document: submittedDocument,
          }),
        });

        window.location.href = "checkout-success";
      } catch (error) {
        console.error(error);

        toast(
          "Something went wrong... Please verify your information is accurate and try again",
          {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
          } as ToastOptions
        );

        isPending.value = false;
      }
    } else {
      try {
        isPending.value = true;

        const res = await fetch(
          "https://app-y4uhxvobbq-uc.a.run.app/create-registration-checkout",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              document: submittedDocument,
            }),
          }
        );
        const data = await res.json();

        window.location.href = data.checkoutSessionUrl;
      } catch (error) {
        console.error(error);

        toast(
          "Something went wrong... Please verify your information is accurate and try again",
          {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
          } as ToastOptions
        );

        isPending.value = false;
      }
    }
  }
}

function formatPhoneNum(modelIndex: number): void {
  let formattedNumber = formInputModel.value[modelIndex]
    .toString()
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (formattedNumber) {
    formInputModel.value[modelIndex] = !formattedNumber[2]
      ? formattedNumber[1]
      : "(" +
        formattedNumber[1] +
        ") " +
        formattedNumber[2] +
        (formattedNumber[3] ? "-" + formattedNumber[3] : "");
  }
}

function applyValidationStyle(index: formFieldEnum): void {
  switch (index) {
    case formFieldEnum.NAME:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );
      break;

    case formFieldEnum.EMAIL:
      formValidationRefs.value[index] = validateAgainstRegex(
        formInputModel.value,
        index,
        EMAIL_REGEX
      );
      break;

    case formFieldEnum.PHONE:
      formValidationRefs.value[index] = validateAgainstRegex(
        formInputModel.value,
        index,
        PHONE_REGEX
      );

      break;
    case formFieldEnum.ALT_PHONE:
      if (formInputModel.value[index].toString().length !== 0) {
        formValidationRefs.value[index] = validateAgainstRegex(
          formInputModel.value,
          index,
          PHONE_REGEX
        );
      }
      break;
    case formFieldEnum.UNIT_BOOKED:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );

      break;
    case formFieldEnum.ARRIVAL_DATE:
      validateDate(formFieldEnum.ARRIVAL_DATE, "void");
      validateDate(formFieldEnum.DEPARTURE_DATE, "void");

      break;
    case formFieldEnum.DEPARTURE_DATE:
      validateDate(formFieldEnum.ARRIVAL_DATE, "void");
      validateDate(formFieldEnum.DEPARTURE_DATE, "void");

      break;
    case formFieldEnum.NUM_ADULTS:
      if (!validateFilled(formInputModel.value, index)) {
        formValidationRefs.value[index] =
          parseInt(formInputModel.value[index].toString()) === 0;
      } else {
        formValidationRefs.value[index] = true;
      }

      break;
    case formFieldEnum.NUM_CHILDREN:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );

      break;
    case formFieldEnum.VERIFY:
      if (formInputModel.value[index] === true)
        formValidationRefs.value[index] = false;
      else formValidationRefs.value[index] = true;

      break;
    default:
      break;
  }
}

function validateDate(
  type: formFieldEnum.ARRIVAL_DATE | formFieldEnum.DEPARTURE_DATE,
  returnType: void | string
): void | string {
  let departureDate = new Date(departureDateRef.value);
  let arrivalDate = new Date(arrivalDateRef.value);

  let workingDate;
  let beforeTodayString;
  let beforeOtherString;

  if (type === formFieldEnum.ARRIVAL_DATE) {
    workingDate = arrivalDate;
    beforeTodayString = "*Arrival Date cannot be before today";
    beforeOtherString = "*Arrival Date cannot be after Departure Date";
  } else {
    workingDate = departureDate;
    beforeTodayString = "*Departure Date cannot be before today";
    beforeOtherString = "*Departure Date cannot be before Arrival Date";
  }

  if (
    workingDate.getTime() <
    stringToDate(
      new Date(new Date().setHours(0, 0, 0, 0)).toLocaleDateString("en-ca")
    ).getTime()
  ) {
    if (returnType === "string") return beforeTodayString;
    formValidationRefs.value[type] = true;
    return;
  }

  if (arrivalDate > departureDate) {
    if (returnType === "string") return beforeOtherString;
    formValidationRefs.value[type] = true;
    return;
  }

  if (returnType === "string") return "*Required";
  formValidationRefs.value[type] = validateFilled(formInputModel.value, type);
}

const getDateValidationString = validateDate;

function getDayDiff(submittedDocument: any): number {
  let Difference_In_Time =
    new Date(submittedDocument.DepartureDate).getTime() -
    new Date(submittedDocument.ArrivalDate).getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return Difference_In_Days;
}

function emitUpdate(): void {
  emit("updateDocument", formInputModel.value);
}

function saveChanges(): void {
  formInputModel.value.forEach((field, index) => {
    if (index !== 9) applyValidationStyle(index);
  });

  formValidationRefs.value[9] = false;

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    emitUpdate();
  }
}

function setArrivalDateRef() {
  if (arrivalDateRef.value) {
    let newArrivalDate = tzAgnosticDateUTC(new Date(arrivalDateRef.value));

    formInputModel.value[formFieldEnum.ARRIVAL_DATE] = newArrivalDate;
  }
}

function setDepartureDateRef() {
  if (departureDateRef.value) {
    let newDepartureDate = tzAgnosticDateUTC(new Date(departureDateRef.value));

    formInputModel.value[formFieldEnum.DEPARTURE_DATE] = newDepartureDate;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: _normalizeClass(["registration-form", { 'no-padding': __props.documentType.length === 0 }]),
      onSubmit: _cache[35] || (_cache[35] = _withModifiers(($event: any) => (handleSubmit()), ["prevent"])),
      novalidate: "true"
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).NAME] }, "registration-form__label--error"])
      }, "*Required", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", { 'check-validation': formValidationRefs.value[_unref(formFieldEnum).NAME] }]),
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).NAME]) = $event)),
        name: "name",
        placeholder: 
          __props.documentType === 'Renter Registration'
            ? 'Name*'
            : 'Guest Name (Primary booking party)*'
        ,
        required: "",
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).NAME))),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).NAME] = false))
      }, null, 42, _hoisted_2), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).NAME]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).EMAIL] }, "registration-form__label--error"])
      }, "*Must be a valid Email Address", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", { 'check-validation': formValidationRefs.value[_unref(formFieldEnum).EMAIL] }]),
        type: "email",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).EMAIL]) = $event)),
        name: "email",
        placeholder: "Email*",
        required: "",
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).EMAIL))),
        onFocus: _cache[5] || (_cache[5] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).EMAIL] = false))
      }, null, 34), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).EMAIL]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).PHONE] }, "registration-form__label--error"])
      }, "*Must be a valid 10-digit phone number", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", { 'check-validation': formValidationRefs.value[_unref(formFieldEnum).PHONE] }]),
        type: "tel",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).PHONE]) = $event)),
        onInput: _cache[7] || (_cache[7] = ($event: any) => (formatPhoneNum(2))),
        name: "phone",
        maxlength: "14",
        pattern: "^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$",
        placeholder: "Guest Cell Phone*",
        required: "",
        onBlur: _cache[8] || (_cache[8] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).PHONE))),
        onFocus: _cache[9] || (_cache[9] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).PHONE] = false))
      }, null, 34), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).PHONE]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).ALT_PHONE] }, "registration-form__label--error"])
      }, " *Must be a valid 10-digit phone number ", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", {
          'check-validation': formValidationRefs.value[_unref(formFieldEnum).ALT_PHONE],
        }]),
        type: "tel",
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).ALT_PHONE]) = $event)),
        onInput: _cache[11] || (_cache[11] = ($event: any) => (formatPhoneNum(3))),
        name: "alt-phone",
        pattern: "\\(\\d{3}\\) \\d{3}-\\d{4}",
        maxlength: "14",
        placeholder: "Alt Phone",
        onBlur: _cache[12] || (_cache[12] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).ALT_PHONE))),
        onFocus: _cache[13] || (_cache[13] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).ALT_PHONE] = false))
      }, null, 34), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).ALT_PHONE]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).UNIT_BOOKED] }, "registration-form__label--error"])
      }, "*Required", 2),
      _withDirectives(_createElementVNode("select", {
        class: _normalizeClass(["registration-form__input", {
          'check-validation': formValidationRefs.value[_unref(formFieldEnum).UNIT_BOOKED],
        }]),
        name: "unit-booked",
        id: "unit-booked",
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).UNIT_BOOKED]) = $event)),
        onBlur: _cache[15] || (_cache[15] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).UNIT_BOOKED))),
        onFocus: _cache[16] || (_cache[16] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).UNIT_BOOKED] = false)),
        required: ""
      }, [
        _cache[37] || (_cache[37] = _createElementVNode("option", {
          value: "",
          disabled: "",
          selected: "",
          hidden: ""
        }, "Unit Booked*", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllUnits), (unit, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: unit
          }, _toDisplayString(unit), 9, _hoisted_3))
        }), 128))
      ], 34), [
        [_vModelSelect, formInputModel.value[_unref(formFieldEnum).UNIT_BOOKED]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).ARRIVAL_DATE] }, "registration-form__label--error offset-right"])
      }, _toDisplayString(_unref(getDateValidationString)(_unref(formFieldEnum).ARRIVAL_DATE, "string")), 3),
      _createElementVNode("div", _hoisted_4, [
        _cache[38] || (_cache[38] = _createElementVNode("label", {
          for: "arrival-date",
          class: "registration-form__date-label spacer"
        }, " Arrival Date* : ", -1)),
        _createVNode(_unref(VueDatePicker), {
          class: _normalizeClass([{
            'check-validation': formValidationRefs.value[_unref(formFieldEnum).ARRIVAL_DATE],
          }, "arrival"]),
          "enable-time-picker": false,
          "month-change-on-scroll": false,
          modelValue: arrivalDateRef.value,
          "onUpdate:modelValue": [
            _cache[17] || (_cache[17] = ($event: any) => ((arrivalDateRef).value = $event)),
            _cache[18] || (_cache[18] = ($event: any) => (setArrivalDateRef()))
          ],
          name: "arrival-date",
          required: "",
          onCleared: _cache[19] || (_cache[19] = ($event: any) => (setArrivalDateRef())),
          onBlur: _cache[20] || (_cache[20] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).ARRIVAL_DATE))),
          onFocus: _cache[21] || (_cache[21] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).ARRIVAL_DATE] = false)),
          "six-weeks": 'fair',
          placeholder: "Select Date",
          vertical: "",
          "teleport-center": __props.documentType === '' ? true : false,
          "auto-apply": "",
          utc: ""
        }, null, 8, ["class", "modelValue", "teleport-center"])
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).DEPARTURE_DATE] }, "registration-form__label--error offset-right"])
      }, _toDisplayString(_unref(getDateValidationString)(_unref(formFieldEnum).DEPARTURE_DATE, "string")), 3),
      _createElementVNode("div", _hoisted_5, [
        _cache[39] || (_cache[39] = _createElementVNode("label", {
          for: "departure-date",
          class: "registration-form__date-label"
        }, " Departure Date* : ", -1)),
        _createVNode(_unref(VueDatePicker), {
          class: _normalizeClass([{
            'check-validation':
              formValidationRefs.value[_unref(formFieldEnum).DEPARTURE_DATE],
          }, "departure"]),
          "enable-time-picker": false,
          "month-change-on-scroll": false,
          modelValue: departureDateRef.value,
          "onUpdate:modelValue": [
            _cache[22] || (_cache[22] = ($event: any) => ((departureDateRef).value = $event)),
            _cache[23] || (_cache[23] = ($event: any) => (setDepartureDateRef()))
          ],
          name: "departure-date",
          disabled: !formInputModel.value[_unref(formFieldEnum).ARRIVAL_DATE],
          required: "",
          onCleared: _cache[24] || (_cache[24] = ($event: any) => (setDepartureDateRef())),
          onBlur: _cache[25] || (_cache[25] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).DEPARTURE_DATE))),
          onFocus: _cache[26] || (_cache[26] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).DEPARTURE_DATE] = false)),
          "six-weeks": 'fair',
          placeholder: "Select Date",
          vertical: "",
          "teleport-center": __props.documentType === '' ? true : false,
          "auto-apply": "",
          utc: ""
        }, null, 8, ["class", "modelValue", "disabled", "teleport-center"])
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).NUM_ADULTS] }, "registration-form__label--error"])
      }, "*Required", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", {
          'check-validation': formValidationRefs.value[_unref(formFieldEnum).NUM_ADULTS],
        }]),
        type: "number",
        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).NUM_ADULTS]) = $event)),
        name: "num-adults",
        placeholder: "Number of Adults*",
        required: "",
        onBlur: _cache[28] || (_cache[28] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).NUM_ADULTS))),
        onFocus: _cache[29] || (_cache[29] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).NUM_ADULTS] = false))
      }, null, 34), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).NUM_ADULTS]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).NUM_CHILDREN] }, "registration-form__label--error"])
      }, "*Required", 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["registration-form__input", {
          'check-validation': formValidationRefs.value[_unref(formFieldEnum).NUM_CHILDREN],
        }]),
        type: "number",
        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).NUM_CHILDREN]) = $event)),
        name: "num-children",
        placeholder: "Number of Children*",
        required: "",
        onBlur: _cache[31] || (_cache[31] = ($event: any) => (applyValidationStyle(_unref(formFieldEnum).NUM_CHILDREN))),
        onFocus: _cache[32] || (_cache[32] = ($event: any) => (formValidationRefs.value[_unref(formFieldEnum).NUM_CHILDREN] = false))
      }, null, 34), [
        [_vModelText, formInputModel.value[_unref(formFieldEnum).NUM_CHILDREN]]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass([{ visible: formValidationRefs.value[_unref(formFieldEnum).VERIFY] }, "registration-form__label--error"])
      }, "*Required", 2),
      (__props.acknowledgeStatement.length !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((formInputModel.value[_unref(formFieldEnum).VERIFY]) = $event)),
              name: "verify",
              required: ""
            }, null, 512), [
              [_vModelCheckbox, formInputModel.value[_unref(formFieldEnum).VERIFY]]
            ]),
            _createElementVNode("label", _hoisted_7, [
              _cache[41] || (_cache[41] = _createTextVNode("Acknowledge ")),
              _createVNode(_component_RouterLink, { to: "/adagio-rules" }, {
                default: _withCtx(() => _cache[40] || (_cache[40] = [
                  _createTextVNode("Rules")
                ])),
                _: 1
              }),
              _createTextVNode(" - " + _toDisplayString(__props.acknowledgeStatement), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.documentType === 'Owner Registration')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((isBypassed).value = $event)),
              name: "bypass"
            }, null, 512), [
              [_vModelCheckbox, isBypassed.value]
            ]),
            _cache[42] || (_cache[42] = _createElementVNode("label", {
              for: "verify",
              class: "registration-form__checkbox-label"
            }, "Please check this box if you are registering a guest who is not paying for their stay (not renting). No transfer fee will be charged, but we do need the stays to be registered for property access. You affirm that no money is changing hands regarding this personal guest of owner ", -1))
          ]))
        : _createCommentVNode("", true),
      (__props.documentType.length !== 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            disabled: isPending.value,
            class: "registration-form__button-submit"
          }, [
            _createElementVNode("div", _hoisted_10, [
              (!isPending.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, "Submit Form"))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[43] || (_cache[43] = [
                    _createElementVNode("div", { class: "form-spinner-circle" }, null, -1)
                  ])))
            ])
          ], 8, _hoisted_9))
        : _createCommentVNode("", true)
    ], 34),
    (__props.documentType.length === 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "save-changes",
          onClick: _cache[36] || (_cache[36] = _withModifiers(($event: any) => (saveChanges()), ["prevent"]))
        }, " Save Changes "))
      : _createCommentVNode("", true)
  ]))
}
}

})