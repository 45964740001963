<template>
  <div class="registration-form__container">
    <form
      class="registration-form"
      :class="{ 'no-padding': documentType.length === 0 }"
      @submit.prevent="handleSubmit()"
      novalidate="true"
    >
      <!-- NAME -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.NAME] }"
        class="registration-form__label--error"
        >*Required</label
      >
      <input
        class="registration-form__input"
        :class="{ 'check-validation': formValidationRefs[formFieldEnum.NAME] }"
        type="text"
        v-model="formInputModel[formFieldEnum.NAME]"
        name="name"
        :placeholder="
          documentType === 'Renter Registration'
            ? 'Name*'
            : 'Guest Name (Primary booking party)*'
        "
        required
        @blur="applyValidationStyle(formFieldEnum.NAME)"
        @focus="formValidationRefs[formFieldEnum.NAME] = false"
      />

      <!-- EMAIL -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.EMAIL] }"
        class="registration-form__label--error"
        >*Must be a valid Email Address</label
      >
      <input
        class="registration-form__input"
        :class="{ 'check-validation': formValidationRefs[formFieldEnum.EMAIL] }"
        type="email"
        v-model="formInputModel[formFieldEnum.EMAIL]"
        name="email"
        placeholder="Email*"
        required
        @blur="applyValidationStyle(formFieldEnum.EMAIL)"
        @focus="formValidationRefs[formFieldEnum.EMAIL] = false"
      />

      <!-- PHONE -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.PHONE] }"
        class="registration-form__label--error"
        >*Must be a valid 10-digit phone number</label
      >
      <input
        class="registration-form__input"
        :class="{ 'check-validation': formValidationRefs[formFieldEnum.PHONE] }"
        type="tel"
        v-model="formInputModel[formFieldEnum.PHONE]"
        @input="formatPhoneNum(2)"
        name="phone"
        maxlength="14"
        pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$"
        placeholder="Guest Cell Phone*"
        required
        @blur="applyValidationStyle(formFieldEnum.PHONE)"
        @focus="formValidationRefs[formFieldEnum.PHONE] = false"
      />

      <!-- ALT PHONE -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.ALT_PHONE] }"
        class="registration-form__label--error"
      >
        *Must be a valid 10-digit phone number
      </label>
      <input
        class="registration-form__input"
        :class="{
          'check-validation': formValidationRefs[formFieldEnum.ALT_PHONE],
        }"
        type="tel"
        v-model="formInputModel[formFieldEnum.ALT_PHONE]"
        @input="formatPhoneNum(3)"
        name="alt-phone"
        pattern="\(\d{3}\) \d{3}-\d{4}"
        maxlength="14"
        placeholder="Alt Phone"
        @blur="applyValidationStyle(formFieldEnum.ALT_PHONE)"
        @focus="formValidationRefs[formFieldEnum.ALT_PHONE] = false"
      />

      <!-- UNIT BOOKED -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.UNIT_BOOKED] }"
        class="registration-form__label--error"
        >*Required</label
      >
      <select
        class="registration-form__input"
        :class="{
          'check-validation': formValidationRefs[formFieldEnum.UNIT_BOOKED],
        }"
        name="unit-booked"
        id="unit-booked"
        v-model="formInputModel[formFieldEnum.UNIT_BOOKED]"
        @blur="applyValidationStyle(formFieldEnum.UNIT_BOOKED)"
        @focus="formValidationRefs[formFieldEnum.UNIT_BOOKED] = false"
        required
      >
        <option value="" disabled selected hidden>Unit Booked*</option>
        <option v-for="(unit, index) in AllUnits" :key="index" :value="unit">
          {{ unit }}
        </option>
      </select>

      <!-- ARRIVAL DATE -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.ARRIVAL_DATE] }"
        class="registration-form__label--error offset-right"
        >{{
          getDateValidationString(formFieldEnum.ARRIVAL_DATE, "string")
        }}</label
      >
      <div class="registration-form__date-container">
        <label for="arrival-date" class="registration-form__date-label spacer">
          Arrival Date* :
        </label>
        <VueDatePicker
          :class="{
            'check-validation': formValidationRefs[formFieldEnum.ARRIVAL_DATE],
          }"
          class="arrival"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          v-model="arrivalDateRef"
          name="arrival-date"
          required
          @update:model-value="setArrivalDateRef()"
          @cleared="setArrivalDateRef()"
          @blur="applyValidationStyle(formFieldEnum.ARRIVAL_DATE)"
          @focus="formValidationRefs[formFieldEnum.ARRIVAL_DATE] = false"
          :six-weeks="'fair'"
          placeholder="Select Date"
          vertical
          :teleport-center="documentType === '' ? true : false"
          auto-apply
          utc
        />
      </div>

      <!-- DEPARTURE DATE -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.DEPARTURE_DATE] }"
        class="registration-form__label--error offset-right"
        >{{
          getDateValidationString(formFieldEnum.DEPARTURE_DATE, "string")
        }}</label
      >
      <div class="registration-form__date-container">
        <label for="departure-date" class="registration-form__date-label">
          Departure Date* :
        </label>
        <VueDatePicker
          :class="{
            'check-validation':
              formValidationRefs[formFieldEnum.DEPARTURE_DATE],
          }"
          class="departure"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          v-model="departureDateRef"
          name="departure-date"
          :disabled="!formInputModel[formFieldEnum.ARRIVAL_DATE]"
          required
          @update:model-value="setDepartureDateRef()"
          @cleared="setDepartureDateRef()"
          @blur="applyValidationStyle(formFieldEnum.DEPARTURE_DATE)"
          @focus="formValidationRefs[formFieldEnum.DEPARTURE_DATE] = false"
          :six-weeks="'fair'"
          placeholder="Select Date"
          vertical
          :teleport-center="documentType === '' ? true : false"
          auto-apply
          utc
        />
      </div>

      <!-- NUM ADULTS -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.NUM_ADULTS] }"
        class="registration-form__label--error"
        >*Required</label
      >
      <input
        class="registration-form__input"
        :class="{
          'check-validation': formValidationRefs[formFieldEnum.NUM_ADULTS],
        }"
        type="number"
        v-model="formInputModel[formFieldEnum.NUM_ADULTS]"
        name="num-adults"
        placeholder="Number of Adults*"
        required
        @blur="applyValidationStyle(formFieldEnum.NUM_ADULTS)"
        @focus="formValidationRefs[formFieldEnum.NUM_ADULTS] = false"
      />

      <!-- NUM CHILDREN -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.NUM_CHILDREN] }"
        class="registration-form__label--error"
        >*Required</label
      >
      <input
        class="registration-form__input"
        :class="{
          'check-validation': formValidationRefs[formFieldEnum.NUM_CHILDREN],
        }"
        type="number"
        v-model="formInputModel[formFieldEnum.NUM_CHILDREN]"
        name="num-children"
        placeholder="Number of Children*"
        required
        @blur="applyValidationStyle(formFieldEnum.NUM_CHILDREN)"
        @focus="formValidationRefs[formFieldEnum.NUM_CHILDREN] = false"
      />

      <!-- VERIFY -->
      <label
        :class="{ visible: formValidationRefs[formFieldEnum.VERIFY] }"
        class="registration-form__label--error"
        >*Required</label
      >
      <div
        v-if="acknowledgeStatement.length !== 0"
        class="registration-form__checkbox-container"
      >
        <input
          type="checkbox"
          v-model="formInputModel[formFieldEnum.VERIFY]"
          name="verify"
          required
        />
        <label for="verify" class="registration-form__checkbox-label"
          >Acknowledge <RouterLink to="/adagio-rules">Rules</RouterLink> -
          {{ acknowledgeStatement }}
        </label>
      </div>

      <!-- BYPASS -->
      <div
        v-if="documentType === 'Owner Registration'"
        class="registration-form__checkbox-container"
      >
        <input type="checkbox" v-model="isBypassed" name="bypass" />
        <label for="verify" class="registration-form__checkbox-label"
          >Please check this box if you are registering a guest who is not
          paying for their stay (not renting). No transfer fee will be charged,
          but we do need the stays to be registered for property access. You
          affirm that no money is changing hands regarding this personal guest
          of owner
        </label>
      </div>

      <!-- SUBMIT -->
      <button
        v-if="documentType.length !== 0"
        :disabled="isPending"
        class="registration-form__button-submit"
      >
        <div class="registration-form__button-inner-container">
          <div v-if="!isPending">Submit Form</div>
          <div class="spinner" v-else>
            <div class="form-spinner-circle"></div>
          </div>
        </div>
      </button>
    </form>
    <button
      v-if="documentType.length === 0"
      class="save-changes"
      @click.prevent="saveChanges()"
    >
      Save Changes
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, defineEmits, onMounted } from "vue";
import { formFieldEnum } from "@/types/formFieldEnum";
import { stringToDate, tzAgnosticDateUTC } from "@/helpers/dateHelpers";
import {
  validateFilled,
  validateAgainstRegex,
} from "@/helpers/validationHelpers";
import { AllUnits } from "@/constants/allUnits";
import { toast, type ToastOptions } from "vue3-toastify";

/*Testing vue datepicker as solution to Safari issue*/
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const props = defineProps({
  acknowledgeStatement: {
    type: String,
    required: true,
  },
  documentType: {
    type: String,
    required: true,
  },
  document: {
    type: Object as any,
  },
});

const emit = defineEmits(["updateDocument"]);

const arrivalDateRef = ref();
const departureDateRef = ref();

const formInputModel = ref(["", "", "", "", "", "", "", "", "", false]);
const isBypassed = ref(false);

onMounted(() => {
  if (props.document) {
    formInputModel.value[formFieldEnum.NAME] = props.document.Name;
    formInputModel.value[formFieldEnum.EMAIL] = props.document.Email;
    formInputModel.value[formFieldEnum.PHONE] = props.document.GuestPhone;
    formInputModel.value[formFieldEnum.ALT_PHONE] = props.document.AltPhone;
    formInputModel.value[formFieldEnum.UNIT_BOOKED] = props.document.Unit;
    arrivalDateRef.value = props.document.ArrivalDate;
    formInputModel.value[formFieldEnum.NUM_ADULTS] = props.document.TotalAdults;
    formInputModel.value[formFieldEnum.NUM_CHILDREN] =
      props.document.TotalChildren;
    setArrivalDateRef();

    setTimeout(() => {
      departureDateRef.value = props.document.DepartureDate;
      setDepartureDateRef();
    }, 0);
  }
});
// clears arrival and departure if arrival date is cleared
watch(formInputModel.value, () => {
  if (formInputModel.value[5] === "") formInputModel.value[6] = "";
});

watch(
  () => formInputModel.value[5],
  () => {
    formInputModel.value[6] = "";
  }
);

const formValidationRefs = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);

const isPending = ref(false);

const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);
const PHONE_REGEX = new RegExp("^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$");

async function handleSubmit(): Promise<void> {
  const submittedDocument = {
    Name: formInputModel.value[formFieldEnum.NAME],
    Email: formInputModel.value[formFieldEnum.EMAIL],
    GuestPhone: formInputModel.value[formFieldEnum.PHONE],
    AltPhone: formInputModel.value[formFieldEnum.ALT_PHONE],
    Unit: formInputModel.value[formFieldEnum.UNIT_BOOKED].toString(),
    ArrivalDate: formInputModel.value[formFieldEnum.ARRIVAL_DATE],
    DepartureDate: formInputModel.value[formFieldEnum.DEPARTURE_DATE],
    TotalAdults: formInputModel.value[formFieldEnum.NUM_ADULTS],
    TotalChildren: formInputModel.value[formFieldEnum.NUM_CHILDREN],
    Acknowledgement: formInputModel.value[formFieldEnum.VERIFY],
    RegistrationDate: new Date(),
    DurationOfStay:
      getDayDiff({
        ArrivalDate: arrivalDateRef.value,
        DepartureDate: departureDateRef.value,
      }) + 1,
  };

  formInputModel.value.forEach((field, index) => {
    applyValidationStyle(index);
  });

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    if (isBypassed.value) {
      try {
        isPending.value = true;

        await fetch("https://app-y4uhxvobbq-uc.a.run.app/bypass-checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            document: submittedDocument,
          }),
        });

        window.location.href = "checkout-success";
      } catch (error) {
        console.error(error);

        toast(
          "Something went wrong... Please verify your information is accurate and try again",
          {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
          } as ToastOptions
        );

        isPending.value = false;
      }
    } else {
      try {
        isPending.value = true;

        const res = await fetch(
          "https://app-y4uhxvobbq-uc.a.run.app/create-registration-checkout",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              document: submittedDocument,
            }),
          }
        );
        const data = await res.json();

        window.location.href = data.checkoutSessionUrl;
      } catch (error) {
        console.error(error);

        toast(
          "Something went wrong... Please verify your information is accurate and try again",
          {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
          } as ToastOptions
        );

        isPending.value = false;
      }
    }
  }
}

function formatPhoneNum(modelIndex: number): void {
  let formattedNumber = formInputModel.value[modelIndex]
    .toString()
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (formattedNumber) {
    formInputModel.value[modelIndex] = !formattedNumber[2]
      ? formattedNumber[1]
      : "(" +
        formattedNumber[1] +
        ") " +
        formattedNumber[2] +
        (formattedNumber[3] ? "-" + formattedNumber[3] : "");
  }
}

function applyValidationStyle(index: formFieldEnum): void {
  switch (index) {
    case formFieldEnum.NAME:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );
      break;

    case formFieldEnum.EMAIL:
      formValidationRefs.value[index] = validateAgainstRegex(
        formInputModel.value,
        index,
        EMAIL_REGEX
      );
      break;

    case formFieldEnum.PHONE:
      formValidationRefs.value[index] = validateAgainstRegex(
        formInputModel.value,
        index,
        PHONE_REGEX
      );

      break;
    case formFieldEnum.ALT_PHONE:
      if (formInputModel.value[index].toString().length !== 0) {
        formValidationRefs.value[index] = validateAgainstRegex(
          formInputModel.value,
          index,
          PHONE_REGEX
        );
      }
      break;
    case formFieldEnum.UNIT_BOOKED:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );

      break;
    case formFieldEnum.ARRIVAL_DATE:
      validateDate(formFieldEnum.ARRIVAL_DATE, "void");
      validateDate(formFieldEnum.DEPARTURE_DATE, "void");

      break;
    case formFieldEnum.DEPARTURE_DATE:
      validateDate(formFieldEnum.ARRIVAL_DATE, "void");
      validateDate(formFieldEnum.DEPARTURE_DATE, "void");

      break;
    case formFieldEnum.NUM_ADULTS:
      if (!validateFilled(formInputModel.value, index)) {
        formValidationRefs.value[index] =
          parseInt(formInputModel.value[index].toString()) === 0;
      } else {
        formValidationRefs.value[index] = true;
      }

      break;
    case formFieldEnum.NUM_CHILDREN:
      formValidationRefs.value[index] = validateFilled(
        formInputModel.value,
        index
      );

      break;
    case formFieldEnum.VERIFY:
      if (formInputModel.value[index] === true)
        formValidationRefs.value[index] = false;
      else formValidationRefs.value[index] = true;

      break;
    default:
      break;
  }
}

function validateDate(
  type: formFieldEnum.ARRIVAL_DATE | formFieldEnum.DEPARTURE_DATE,
  returnType: void | string
): void | string {
  let departureDate = new Date(departureDateRef.value);
  let arrivalDate = new Date(arrivalDateRef.value);

  let workingDate;
  let beforeTodayString;
  let beforeOtherString;

  if (type === formFieldEnum.ARRIVAL_DATE) {
    workingDate = arrivalDate;
    beforeTodayString = "*Arrival Date cannot be before today";
    beforeOtherString = "*Arrival Date cannot be after Departure Date";
  } else {
    workingDate = departureDate;
    beforeTodayString = "*Departure Date cannot be before today";
    beforeOtherString = "*Departure Date cannot be before Arrival Date";
  }

  if (
    workingDate.getTime() <
    stringToDate(
      new Date(new Date().setHours(0, 0, 0, 0)).toLocaleDateString("en-ca")
    ).getTime()
  ) {
    if (returnType === "string") return beforeTodayString;
    formValidationRefs.value[type] = true;
    return;
  }

  if (arrivalDate > departureDate) {
    if (returnType === "string") return beforeOtherString;
    formValidationRefs.value[type] = true;
    return;
  }

  if (returnType === "string") return "*Required";
  formValidationRefs.value[type] = validateFilled(formInputModel.value, type);
}

const getDateValidationString = validateDate;

function getDayDiff(submittedDocument: any): number {
  let Difference_In_Time =
    new Date(submittedDocument.DepartureDate).getTime() -
    new Date(submittedDocument.ArrivalDate).getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return Difference_In_Days;
}

function emitUpdate(): void {
  emit("updateDocument", formInputModel.value);
}

function saveChanges(): void {
  formInputModel.value.forEach((field, index) => {
    if (index !== 9) applyValidationStyle(index);
  });

  formValidationRefs.value[9] = false;

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    emitUpdate();
  }
}

function setArrivalDateRef() {
  if (arrivalDateRef.value) {
    let newArrivalDate = tzAgnosticDateUTC(new Date(arrivalDateRef.value));

    formInputModel.value[formFieldEnum.ARRIVAL_DATE] = newArrivalDate;
  }
}

function setDepartureDateRef() {
  if (departureDateRef.value) {
    let newDepartureDate = tzAgnosticDateUTC(new Date(departureDateRef.value));

    formInputModel.value[formFieldEnum.DEPARTURE_DATE] = newDepartureDate;
  }
}
</script>

<style>
.registration-form {
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-bottom: 12rem;
}

.no-padding {
  padding: 0 !important;
}
/* Small devices (portrait tablets and large phones, 600px-768px) */
@media only screen and (max-width: 768px) {
  .registration-form {
    margin: 0 1rem;
  }
}

.registration-form__input,
.dp__input {
  width: 100%;
  font-size: 18px;
  background-color: rgb(241, 241, 241) !important;
  border: none;
  height: 50px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
}

/* gets rid of incrementer arrows */
.registration-form__input::-webkit-outer-spin-button,
.registration-form__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.registration-form__input:disabled {
  opacity: 0.5;
}

.check-validation:invalid {
  outline: 1px solid red;
}

.registration-form__input:focus {
  outline: 1px solid black;
}

.registration-form__input::placeholder {
  color: rgb(59, 59, 59);
}

.registration-form__button-submit {
  width: 12rem;
  height: 4rem;
  align-self: center;
  margin-top: 1rem;
}

.registration-form__date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-form__date-label {
  font-size: 18px;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(59, 59, 59);
  padding: 1rem;
  text-wrap: nowrap;
}

.registration-form__date-input {
  flex: 1;
}

.spacer {
  padding-right: 2.8rem;
}

.offset-right {
  margin-left: 10.75rem;
}

.registration-form__checkbox-label {
  text-align: left;
  padding-left: 1rem;
  color: rgb(59, 59, 59);

  a {
    text-decoration: none;
    color: rgb(0, 117, 193);
  }

  a:hover {
    color: rgb(0, 73, 124);
  }
}

.registration-form__checkbox-container {
  display: flex;
  margin-bottom: 1rem;
}

.registration-form__label--error {
  color: red;
  font-size: 0.75rem;
  text-align: left;
  position: relative;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

.form-spinner-circle {
  margin: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-color: white white transparent transparent;
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 405px) {
  .registration-form__date-label {
    font-size: 1rem;
  }
}

.dp__input {
  padding-left: 2rem;
}

.dp__btn {
  &:hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

.dp--clear-btn {
  &:hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

.dp__month_year_select {
  &:hover {
    background-color: rgba(238, 238, 238, 0.504) !important;
  }
}

.dp__menu {
  max-width: 20rem;
}

.arrival {
  .dp__menu {
    &::before {
      content: "Arrival Date";
    }
  }
}

.departure {
  .dp__menu {
    &::before {
      content: "Departure Date";
    }
  }
}

.dp__month_year_wrap {
  align-items: center;
}

.dp--arrow-btn-nav {
  padding: 0;
}

.dp__disabled {
  color: #666666;
  background-color: rgb(248, 248, 248) !important;
}
</style>
